import { useTranslation } from "react-i18next";
import { notFound } from "../../assets";
import styles from "./NotFoundPage.module.css";

export const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={styles.notFound}>
        <img src={notFound} alt="" />
        <p>{t("header.content.notFound")}</p>
      </div>
    </div>
  );
};
