import styles from "./Footer.module.css";
import { Links } from "../../constants";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FB, TG, Insta, twitter } from "../../assets";

export const Footer = () => {
  const { t } = useTranslation();
  const links = Links();
  return (
    <footer>
      <div className="container">
        <div className={styles.footer}>
          <div className={styles.links}>
            {links.map((item) => (
              <Link key={item.id} to="#">
                {item.name}
              </Link>
            ))}
          </div>
          <div className={styles.prava}>
            <h2>{t("header.title.title")}</h2>
            <p>{t("header.content.prava")}</p>
            <ul className={styles.social}>
              <li>
                <a href="#">
                  <img src={FB} alt="" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src={TG} alt="" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src={Insta} alt="" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src={twitter} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
