import styles from "./PersonCard.module.css";
import { call, world, mail } from "../../assets";

export const PersonCard = ({ img, name, range, number, link, web }) => {
  return (
    <div className={styles.ministryBox}>
      <img src={img} alt="" />
      <span className={styles.ministrName}>
        {name}
        <i>{range}</i>
      </span>
      <ul className={styles.ministrList}>
        <li>
          <span>
            <img src={call} alt="" />
          </span>
          <a href={`tel: ${number}`} target="_blank">
            {number}
          </a>
        </li>
        <li>
          <span>
            <img src={mail} alt="" />
          </span>
          <a href={`mailto: ${link}`} target="_blank">
            {link}
          </a>
        </li>
        <li>
          <span>
            <img src={world} alt="" />
          </span>
          <a href={web} target="_blank">
            {web}
          </a>
        </li>
      </ul>
    </div>
  );
};
