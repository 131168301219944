import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import { kalendar } from "../../assets";
import styles from "./MainNewsCard.module.css";

export const MainNewsCard = ({ img, title, date }) => {
  const { t } = useTranslation();
  let dateObject = new Date();
  let options = { day: "numeric", month: "long" };
  let [number, month] = dateObject
    .toLocaleDateString("ru-RU", options)
    .split(" ");
  const [isVisible, setIsVisible] = useState(false);

  const handleWaypointEnter = () => {
    setIsVisible(true);
  };
  return (
    <>
      <div className={`${styles.mainNews} ${isVisible ? styles.visible : ""}`}>
        <div className={styles.ten}>
          <img src={img} alt="" />
        </div>
        <Waypoint onEnter={handleWaypointEnter} />
        <div className={styles.content}>
          <Link to="/6/news/1">
            <div className={styles.title}>
              <div className={styles.date}>
                <img src={kalendar} alt="" />
                <span>{number + ' ' + month}</span>
              </div>
              <p>{title}</p>
            </div>
          </Link>
          <div className={styles.dop}>
            <span>{date}</span>
            <Link to="/news">{t("header.content.vazirlik")}</Link>
          </div>
        </div>
      </div>
    </>
  );
};
