import { useParams } from "react-router-dom";
import styles from "./Page.module.css";
import { Sidebar } from "../../components";
import { NewsPage } from "../newsPage/NewsPage";
import { Category } from "../categoryPage/Category";

export const Page = () => {
  const { id, first, newsId } = useParams();
  return (
    <div className="container">
      <div className={styles.page}>
        <div className={styles.content}>
          {newsId ? (
            <NewsPage id={id} newsId={newsId} />
          ) : (
            <Category first={first} id={id} />
          )}
        </div>
        <Sidebar />
      </div>
    </div>
  );
};
