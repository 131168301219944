import { createBrowserRouter } from "react-router-dom";
import { Home, Murojat, Rahbariyat } from "../../features";
import { NotFoundPage } from "../../features/notFound/NotFoundPage";
import { Page } from "../../features/pages/Page";
import { AppLayout } from "../../layout";

export const appRoutes = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
      {
        path: ":id/:first",
        element: <Page />,
      },
      {
        path: ":id/news/:newsId",
        element: <Page />,
      },
      {
        path: ":id/guides",
        element: <Rahbariyat />,
      },
      {
        path: ":id/staff",
        element: <Rahbariyat />,
      },
      {
        path: ":id/murojat",
        element: <Murojat />,
      },
    ],
  },
]);
