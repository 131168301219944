import styles from "./Rahbariyat.module.css";
import { PersonCard } from "../../components/personCard/PersonCard";
import { Sidebar } from "../../components";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

export const Rahbariyat = () => {
  const { t } = useTranslation();
  const href = window.location.href.slice(24);

  useEffect(() => {
    fetch("https://api.qr-bantlik.uz/guidance.php")
      .then((res) => res.json())
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }, []);

  const rahbar = {
    img: "https://gov.uz/_next/image/?url=https%3A%2F%2Fapi-portal.gov.uz%2Fuploads%2F74572c15-9b3a-65b0-b55b-e9ef43380773_guide_307.jpg&w=1920&q=75",
    name: "Musaev Behzod Anvarovich",
    range:
      "O‘zbekiston Respublikasi kambag‘allikni qisqartirish va bandlik vaziri",
    number: "+ 99871 239-41-21",
    link: "info@mehnat.uz",
    web: "https://mehnat.uz/",
  };
  return (
    <div className="container">
      <div className={styles.page}>
        <div className={styles.content}>
          <h2 className={styles.title}>
            {href === "guides"
              ? t("header.vazirlik.l3")
              : t("header.vazirlik.l4")}
          </h2>
          <PersonCard {...rahbar} />
          <PersonCard {...rahbar} />
        </div>
        <Sidebar />
      </div>
    </div>
  );
};
