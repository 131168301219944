import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { cases } from "../../assets";
import styles from "./Vakansiya.module.css";

export const Vakansiya = ({ text, to }) => {
  const { t } = useTranslation();
  return (
    <Link to={to} className={styles.link}>
      <span>
        <img src={cases} alt="" />
      </span>
      {text}
      <div>
        {t("header.content.orin")}
        <i>0</i>
      </div>
    </Link>
  );
};
