import styles from './MalumotYoq.module.css'
import { useTranslation } from "react-i18next";
import { nodata } from "../../assets";

export const MalumotYoq = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.nodata}>
      <img src={nodata} alt="" />
      <p>{t("header.content.yoq")}</p>
    </div>
  );
};
