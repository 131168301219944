import style from "./Search.module.css";
import { lupa } from "../../assets";

export const Search = () => {
  return (
    <form className={style.form}>
      <input type="text" />
      <button>
        <img src={lupa} alt="" />
      </button>
    </form>
  );
};
