// import Swiper core and required modules
import { Navigation, Autoplay } from "swiper/modules";
import {
  swiperRu1,
  swiperRu2,
  swiperRu3,
  swiperUz1,
  swiperUz2,
  swiperUz3,
} from "../../assets";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useTranslation } from "react-i18next";

export const Corusel = () => {
  const { i18n } = useTranslation();
  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, Autoplay]}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
    >
      {i18n.language == "ru" ? (
        <>
          <SwiperSlide>
            <a href="https://my.mehnat.uz/">
              <img src={swiperRu1} alt="" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://play.google.com/store/apps/details?id=uz.mehnat.mening_ishim">
              <img src={swiperRu2} alt="" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://ish.mehnat.uz/">
              <img src={swiperRu3} alt="" />
            </a>
          </SwiperSlide>
        </>
      ) : (
        <>
          <SwiperSlide>
            <a href="https://my.mehnat.uz/">
              <img src={swiperUz1} alt="" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://play.google.com/store/apps/details?id=uz.mehnat.mening_ishim">
              <img src={swiperUz2} alt="" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://ish.mehnat.uz/">
              <img src={swiperUz3} alt="" />
            </a>
          </SwiperSlide>
        </>
      )}
    </Swiper>
  );
};
