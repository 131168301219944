import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MainNewsCard } from "../components/mainNewsCard/MainNewsCard";
import { OtherNewsCard } from "../components/otherNewsCard/OtherNewsCard";
import { Kalendar, MalumotYoq, Vakansiya, YonalishCard } from "../components";
import { Links } from "../constants/constants";
import styles from "./Home.module.css";
import { Waypoint } from "react-waypoint";
import { useEffect, useState } from "react";
import { hukumat, internet, matbuot, oliy, qonun, news } from "../assets";
import { Corusel } from "../components/corusel/Corusel";

export const Home = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const { t } = useTranslation();
  const infromatio = {
    id: 1,
    img: "https://yuz.uz/imageproxy/1200x/https://yuz.uz/file/news/2da2eeb04595c5ccbfb117f3fa68c282.jpg",
    title:
      "Ayni damda Toshkent shahridagi “Ishga marhamat” monomarkazida “Mahalla institutining jamiyat hayotida tutgan o‘rni” mavzusidagi Xalqaro forum boshlanish arafasida.",
    date: new Date().toLocaleString(),
  };
  const kalendar = {
    sub: "Постановление Президента Республики Узбекистан №ПП-329. Дата принятия 10.10.2023. Дата вступления в силу 11.10.2023",
    url: "https://lex.uz/ru/docs/6631604",
    urlText:
      "О мерах по коренному реформированию сферы железнодорожного транспорта Республики Узбекистан",
    date: "10.10.2023",
  };
  const links = Links(2);
  const links2 = Links(3).slice(5);

  const [weatherData, setWeatherData] = useState(null);

  const apiKey = "d526a593a321687e598dfe578ac0ddf2";
  const apiUrl = `https://api.openweathermap.org/data/2.5/weather?q=Нукус&appid=${apiKey}`;

  useEffect(() => {
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => setWeatherData(data))
      .catch((error) => console.error("Error fetching weather data: ", error));
  }, []);
  return (
    <>
      <img
        src="https://www.thebusinessconcept.com/wp-content/uploads/2023/08/Business-man-touching-on-interface-panel-of-global-network-distribution.jpg"
        alt=""
        className="fonImg"
      />
      <div className="fon" />
      <div className={styles.home}>
        <div className="container">
          <section className={styles.newsSection}>
            <div className={styles.newsTitle}>
              <img src={news} alt="" />
              <h2>{t("header.content.yangilik")}</h2>
            </div>
            <section className={styles.news}>
              <MainNewsCard {...infromatio} />
              <div className={styles.other}>
                <OtherNewsCard {...infromatio} />
                <OtherNewsCard {...infromatio} />
                <OtherNewsCard {...infromatio} />
              </div>
            </section>
            <div className={styles.barcha}>
              <Link to="/6/news">{t("header.content.barcha")}</Link>
            </div>
          </section>
          <section className={styles.faoliyat}>
            <h2>{t("header.content.faoliyat")}</h2>
            <Waypoint onEnter={() => setIsVisible(true)} />
            <ul
              className={`${styles.faoliyatLinks} ${
                isVisible ? styles.visible : ""
              }`}
            >
              {links.map((item) => (
                <li key={item.id}>
                  <YonalishCard text={item.title} to={item.to} />
                </li>
              ))}
            </ul>
            <div className={styles.barcha}>
              <Link to="/remuneration">{t("header.content.batafsil")}</Link>
            </div>
          </section>

          <Corusel />

          <section
            className={`${styles.xizmatlar} ${
              isVisible1 ? styles.visible : ""
            }`}
          >
            <div className={styles.xizmatlarBlock}>
              <div>
                <h2>{t("header.content.inter")}</h2>
                {links2.length ? (
                  links2.map((item) => (
                    <YonalishCard
                      key={item.id}
                      text={item.title}
                      to={item.to}
                    />
                  ))
                ) : (
                  <MalumotYoq />
                )}
              </div>
              <Waypoint onEnter={() => setIsVisible1(true)} />
              <div className={styles.middle}>
                <h2>{t("header.content.kalendar")}</h2>
                {false ? (
                  links2.map((item) => (
                    <YonalishCard text={item.title} to={item.to} />
                  ))
                ) : (
                  <MalumotYoq />
                )}
              </div>
              <div>
                <h2>{t("header.content.polezniy")}</h2>
                {false ? (
                  links2.map((item) => (
                    <YonalishCard text={item.title} to={item.to} />
                  ))
                ) : (
                  <MalumotYoq />
                )}
              </div>
            </div>
          </section>
        </div>

        <section
          className={`${styles.xizmatlar} ${styles.shadow} ${
            isVisible2 ? styles.visible : ""
          }`}
        >
          <div className="container">
            <div className={styles.xizmatlarBlock}>
              <div className={styles.inter}>
                <h2>{t("header.content.ish")}</h2>
                <ul>
                  {links2.length ? (
                    links2.map((item) => (
                      <li key={item.id}>
                        <Vakansiya text={item.title} to={item.to} />
                      </li>
                    ))
                  ) : (
                    <MalumotYoq />
                  )}
                </ul>
              </div>
              <Waypoint onEnter={() => setIsVisible2(true)} />
              <div>
                <h2>{t("header.content.hujjat")}</h2>
                <ul>
                  {true ? (
                    links2
                      .slice(1)
                      .map((item) => <Kalendar key={item.id} {...kalendar} />)
                  ) : (
                    <MalumotYoq />
                  )}
                </ul>
              </div>
              <div>
                <h2>{t("header.content.sorov")}</h2>
                {false ? (
                  links2.map((item) => (
                    <YonalishCard text={item.title} to={item.to} />
                  ))
                ) : (
                  <MalumotYoq />
                )}
              </div>
            </div>
          </div>
        </section>

        <section className={`container ${styles.cont}`}>
          <div className={styles.weather}>
            <h1>Погода в Нукусе</h1>
            <p>{(weatherData?.main?.temp - 273.15).toFixed(2)}°C</p>
          </div>
          <a href="https://lex.uz/docs/-6396146" target="_blank">
            <img
              src="https://gov.uz/_next/image/?url=https%3A%2F%2Fapi-portal.gov.uz%2Fuploads%2F48e83973-e5f1-fd13-df0f-f8389ec95357_media_54.png&w=384&q=75"
              alt=""
            />
          </a>
          <a href="https://cbu.uz/oz/arkhiv-kursov-valyut/" target="_blank">
            www.cbu.uz dan MB valyuta kurslari
          </a>
        </section>

        <section className={styles.foydali}>
          <a href="http://www.press-service.uz/" target="_blank">
            <img src={matbuot} alt="" />
            <p>{t("header.content.matbuot")}</p>
          </a>
          <a
            href="http://parliament.gov.uz/?_gl=1*tqmqrf*_ga*MTgwNTcxMzI5OC4xNjk2NzQ3NjEy*_ga_XESZL19D7L*MTY5NzI5OTIxNC4zMi4wLjE2OTcyOTkyMTQuMC4wLjA."
            target="_blank"
          >
            <img src={qonun} alt="" />
            <p>{t("header.content.qonun")}</p>
          </a>
          <a
            href="http://senat.gov.uz/?_gl=1*1jbkg45*_ga*MTgwNTcxMzI5OC4xNjk2NzQ3NjEy*_ga_XESZL19D7L*MTY5NzI5OTIxNC4zMi4wLjE2OTcyOTkyMTQuMC4wLjA."
            target="_blank"
          >
            <img src={oliy} alt="" />
            <p>{t("header.content.senat")}</p>
          </a>
          <a
            href="https://www.gov.uz/?_gl=1*1jbkg45*_ga*MTgwNTcxMzI5OC4xNjk2NzQ3NjEy*_ga_XESZL19D7L*MTY5NzI5OTIxNC4zMi4wLjE2OTcyOTkyMTQuMC4wLjA."
            target="_blank"
          >
            <img src={hukumat} alt="" />
            <p>{t("header.content.portal")}</p>
          </a>
          <a
            href="https://www.gov.uz/?_gl=1*1jbkg45*_ga*MTgwNTcxMzI5OC4xNjk2NzQ3NjEy*_ga_XESZL19D7L*MTY5NzI5OTIxNC4zMi4wLjE2OTcyOTkyMTQuMC4wLjA."
            target="_blank"
          >
            <img src={internet} alt="" />
            <p>{t("header.content.internet")}</p>
          </a>
        </section>
      </div>
    </>
  );
};
