import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Sidebar } from "../../components";
import { Links } from "../../constants";
import styles from "./Murojat.module.css";

export const Murojat = () => {
  const handlePost = (e) => {
    e.preventDefault();
  };
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={styles.page}>
        <div className={styles.content}>
          <div className={styles.title}>
            <Link to="/">{t("header.title.bosh")}</Link>
            <span>►</span>
            <span>{t(`header.l7`)}</span>
          </div>
          <h2>{t("header.content.aloqa")}</h2>
          <form onSubmit={handlePost}>
            <label htmlFor="fio">
              <span>{t("header.content.fio")}</span>
              <input
                type="text"
                required
                placeholder={t("header.content.fio")}
                id="fio"
              />
            </label>
            <label htmlFor="mail">
              <span>{t("header.content.email")}</span>
              <input
                type="email"
                required
                placeholder={t("header.content.email")}
                id="mail"
              />
            </label>
            <label htmlFor="tel">
              <span>{t("header.content.tel")}</span>
              <input
                type="number"
                required
                placeholder={t("header.content.tel")}
                id="tel"
              />
            </label>
            <label htmlFor="savol">
              <span>{t("header.content.savol")}</span>
              <textarea
                required
                placeholder={t("header.content.savol")}
                id="savol F"
              />
            </label>
            <input type="submit" onSubmit={handlePost} />
          </form>
        </div>
        <Sidebar />
      </div>
    </div>
  );
};
