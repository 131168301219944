import { doc } from "../../assets";
import styles from "./Kalendar.module.css";

export const Kalendar = ({ sub, url, urlText, date }) => {
  return (
    <li className={styles.kalendar}>
      <img src={doc} alt="" />
      <span>{sub}</span>
      <a href={url} target="_blank">
        {urlText}
      </a>
      <i>{date}</i>
    </li>
  );
};
