import { Link } from "react-router-dom";
import styles from "./CategoryCard.module.css";

export const CategoryCard = ({ img, title, date, id }) => {
  let dateObject = new Date(date);
  let options = { day: "numeric", month: "short" };
  let [number, month] = dateObject
    .toLocaleDateString("ru-RU", options)
    .split(" ");
  return (
    <div className={styles.categoryCard}>
      <div className={styles.img}>
        <img src={img} alt="" />
      </div>
      <p>
        <Link to={`/6/news/${id}`}>{title}</Link>
      </p>
      <div className={styles.dates}>
        <span>{month.split('.')}</span>
        <span>{number}</span>
      </div>
    </div>
  );
};
