import { NotFoundPage } from "../notFound/NotFoundPage";
import styles from "./NewsPage.module.css";
import { pechat, FB, TG, Insta, twitter } from "../../assets";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const NewsPage = ({ id, newsId }) => {
  const { t } = useTranslation();
  const infromatio = {
    id: 1,
    img: "https://yuz.uz/imageproxy/1200x/https://yuz.uz/file/news/2da2eeb04595c5ccbfb117f3fa68c282.jpg",
    title:
      "Ayni damda Toshkent shahridagi “Ishga marhamat” monomarkazida “Mahalla institutining jamiyat hayotida tutgan o‘rni” mavzusidagi Xalqaro forum boshlanish arafasida.",
    message: `Italiyaning Turin shahrida Yevropa Ittifoqi va Yevropa Ta’lim Jamg‘armasining
      “Markaziy Osiyodagi iqtidorli yoshlar uchun harakat va muloqot” loyihasi muhokamasiga bag‘ishlangan yuqori darajali guruh uchrashuvi bo‘lib o‘tdi.
         
       Unda  Markaziy Osiyo ta’lim va mehnat vaziri o’rinbosarlari qatorida Kambag’allikni qisqartirish va bandlik vaziri o’rinbosari Ra’no Turdiboyeva hamda vazirlik vakillari  ishtirok etdi. 
      
      Shuningdek, vazirlik delegatsiyasi  Yevropaning eng ilg‘or texnika universitetlaridan biri bo‘lgan Turin politexnika ( Politecnico di Torino) universiteti faoliyati bilan tanishdi. 
      
      Universitet rektori Gvido Sarako bilan bo’lib o’tgan suhbatda mazkur o’quv maskanining Toshkent shahri filialidagi  bitiruvchilarni bandligini ta’minlash bo’yicha hamkorlikni yo’lga qo’yish yuzasidan takliflar berildi.`,
    date: new Date().toLocaleString(),
  };
  return (
    <div className={styles.singleNewsPage}>
      {infromatio.id == newsId ? (
        <>
          <div className={styles.title}>
            <Link to="/">{t("header.title.bosh")}</Link>
            <span>►</span>
            <span>{t(`header.l${id}`)}</span>
          </div>
          <h2>{infromatio.title}</h2>
          <span onClick={() => window.print()}>
            <img src={pechat} alt="" />
          </span>
          <p>{infromatio.date}</p>
          <div className={styles.img}>
            <img src={infromatio.img} alt="" />
          </div>
          <p>{infromatio.message}</p>
          <ul className={styles.social}>
            <li>
              <a href="#">
                <img src={FB} alt="" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src={TG} alt="" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src={Insta} alt="" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src={twitter} alt="" />
              </a>
            </li>
          </ul>
        </>
      ) : (
        <NotFoundPage />
      )}
    </div>
  );
};
