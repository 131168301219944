import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import { Links } from "../../constants";
import { useMatchMedia } from "../../hooks";
import stylse from "./Sidebar.module.css";

export const Sidebar = () => {
  const { id } = useParams();
  const links = Links(id);
  const [open, setOpen] = useState(false);
  const { isMobile } = useMatchMedia();
  const { t } = useTranslation();
  return (
    <div className={stylse.sidebar}>
      {isMobile && (
        <h2 className={stylse.opener} onClick={() => setOpen(!open)}>
          {t("header.title.map")}
        </h2>
      )}
      {isMobile ? (
        <div
          className={stylse.sidebar}
          style={open ? { display: "flex" } : { display: "none" }}
        >
          {links.map((i) => (
            <NavLink key={i.id} to={i.to}>
              {i.title}
            </NavLink>
          ))}
        </div>
      ) : (
        <div className={stylse.sidebar}>
          {links.map((i) => (
            <NavLink key={i.id} to={i.to}>
              {i.title}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};
