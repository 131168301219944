import styles from "./Header.module.css";
import { FB, TG, Insta, menu, gerb, call, twitter } from "../../assets";
import { Link, NavLink } from "react-router-dom";
import { Links } from "../../constants";
import { useMatchMedia } from "../../hooks";
import { useState } from "react";
import { Language } from "../language/Language";
import { Search } from "../search/Search";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export const Header = () => {
  const { t } = useTranslation();

  const links = Links();

  const [mapOpen, setMapOpen] = useState(false);

  document.addEventListener("click", () => {
    setMapOpen(false);
  });

  const toogleMenu = (e) => {
    e.stopPropagation();
    setMapOpen(!mapOpen);
  };
  const toogleMen = (e) => {
    e.stopPropagation();
    setMapOpen(true);
  };

  const { isMobile } = useMatchMedia();

  useEffect(() => {}, [isMobile]);

  return (
    <header className={styles.containerHeader}>
      <div className={styles.header}>
        <h3 className={styles.test}>{t("header.content.test")}</h3>
        {isMobile ? (
          <div className={styles.mobile}>
            <div className={styles.map}>
              <img src={menu} onClick={toogleMenu} alt="" />
              <div
                onClick={toogleMen}
                style={
                  mapOpen
                    ? { opacity: 1, pointerEvents: "all" }
                    : { opacity: 0, pointerEvents: "none" }
                }
              >
                <ul className={styles.social}>
                  <li>
                    <a href="#">
                      <img src={FB} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={TG} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={Insta} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={twitter} alt="" />
                    </a>
                  </li>
                </ul>
                <div>
                  <div className={styles.call}>
                    <img src={call} alt="" />
                    <div>
                      <p>{t("header.title.telefon")}</p>
                      <p>+99871 203-01-41</p>
                    </div>
                  </div>
                  <Language />
                </div>
                <h4>{t("header.title.map")}</h4>
                <div className={styles.mapLinks}>
                  {links.map((item, i) => (
                    <ul key={item.name + i}>
                      <h5 key={item.name}>- {item.name}</h5>
                      {Links(item.id).map((i) => (
                        <>
                          <Link key={i.id} to={i.to}>
                            {i.title}
                          </Link>
                        </>
                      ))}
                    </ul>
                  ))}
                </div>
              </div>
            </div>
            <div className={styles.logo}>
              <h2>{t("header.title.title")}</h2>
              <img src={gerb} alt="" />
            </div>
          </div>
        ) : (
          <nav>
            <ul className={styles.links}>
              {links.map((item) => {
                const l = Links(item.id);
                return (
                  <li key={item.id} className={styles.drop}>
                    <Link to={item.to}>{item.name} ↓</Link>
                    <div>
                      {l.map((link) => (
                        <Link key={link.id} to={link.to}>
                          {link.title}
                        </Link>
                      ))}
                    </div>
                  </li>
                );
              })}
              <div className={styles.map}>
                <img src={menu} onClick={toogleMenu} alt="" />
                <div
                  onClick={toogleMen}
                  style={
                    mapOpen
                      ? { opacity: 1, pointerEvents: "all" }
                      : { opacity: 0, pointerEvents: "none" }
                  }
                >
                  <h4>{t("header.title.map")}</h4>
                  <div className={styles.mapLinks}>
                    {links.map((item) => (
                      <ul key={item.id}>
                        <h5 key={item.name}>- {item.name}</h5>
                        {Links(item.id).map((i) => (
                          <Link key={i.id} to={i.to}>
                            {i.title}
                          </Link>
                        ))}
                      </ul>
                    ))}
                  </div>
                </div>
              </div>
            </ul>
            <section className={styles.headingTitle}>
              <div className={styles.call}>
                <img src={call} alt="" />
                <p>{t("header.title.telefon")}</p>
                <p>+99871 203-01-41</p>
              </div>
              <div className={styles.logo}>
                <h2>{t("header.title.title")}</h2>
                <img src={gerb} alt="" />
              </div>
              <div className={styles.other}>
                <Search />
                <Language />
                <ul className={styles.social}>
                  <li>
                    <a href="#">
                      <img src={FB} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={TG} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={Insta} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={twitter} alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </section>
          </nav>
        )}
      </div>
    </header>
  );
};
