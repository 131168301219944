import { useTranslation } from "react-i18next";

export const Links = (type) => {
  const { t } = useTranslation();
  const links = [
    {
      id: 1,
      name: t("header.l1"),
    },
    {
      id: 2,
      name: t("header.l2"),
    },
    {
      id: 3,
      name: t("header.l3"),
    },
    {
      id: 4,
      name: t("header.l4"),
    },
    {
      id: 5,
      name: t("header.l5"),
    },
    {
      id: 6,
      name: t("header.l6"),
    },
    {
      id: 7,
      name: t("header.l7"),
    },
  ];
  const extraLinks = {
    l1: [
      {
        id: 1,
        to: "/1/about",
        title: t("header.vazirlik.l1"),
      },
      {
        id: 2,
        to: "https://mehnat.uz/oz/pages/vazirlik-tuzilmasi",
        title: t("header.vazirlik.l2"),
      },
      {
        id: 3,
        to: "/1/guides",
        title: t("header.vazirlik.l3"),
      },
      {
        id: 4,
        to: "/1/staff",
        title: t("header.vazirlik.l4"),
      },
      {
        id: 5,
        to: "/1/regional",
        title: t("header.vazirlik.l5"),
      },
      {
        id: 6,
        to: "/1/jamoatchilik-kengashi",
        title: t("header.vazirlik.l6"),
      },
      {
        id: 7,
        to: "https://ish.mehnat.uz/",
        title: t("header.vazirlik.l7"),
      },
      {
        id: 8,
        to: "/1/pages/about",
        title: t("header.vazirlik.l8"),
      },
    ],
    l2: [
      {
        id: 1,
        to: "/2/statistic",
        title: t("header.faoliyat.l1"),
      },
      {
        id: 2,
        to: "/2/kambagallikni_qisqartirish",
        title: t("header.faoliyat.l2"),
      },
      {
        id: 3,
        to: "/2/employment",
        title: t("header.faoliyat.l3"),
      },
      {
        id: 4,
        to: "/2/protection",
        title: t("header.faoliyat.l4"),
      },
      {
        id: 5,
        to: "/2/remuneration",
        title: t("header.faoliyat.l5"),
      },
      {
        id: 6,
        to: "/2/training_retraining",
        title: t("header.faoliyat.l6"),
      },
      {
        id: 7,
        to: "/2/entrepreneurship",
        title: t("header.faoliyat.l7"),
      },
      {
        id: 8,
        to: "/2/anti_corruption",
        title: t("header.faoliyat.l8"),
      },
      {
        id: 9,
        to: "/2/international_cooperation",
        title: t("header.faoliyat.l9"),
      },
    ],
    l3: [
      {
        id: 1,
        to: "/3/xizmat",
        title: t("header.xizmat.l1"),
      },
      {
        id: 2,
        to: "/3/xizmat1",
        title: t("header.xizmat.l2"),
      },
      {
        id: 3,
        to: "/3/xizmat2",
        title: t("header.xizmat.l3"),
      },
      {
        id: 4,
        to: "/3/xizmat3",
        title: t("header.xizmat.l4"),
      },
      {
        id: 5,
        to: "/3/xizmat4",
        title: t("header.xizmat.l5"),
      },
      {
        id: 6,
        to: "/3/xizmat5",
        title: t("header.xizmat.l6"),
      },
      {
        id: 7,
        to: "/3/xizmat6",
        title: t("header.xizmat.l7"),
      },
      {
        id: 8,
        to: "/3/xizmat7",
        title: t("header.xizmat.l8"),
      },
      {
        id: 9,
        to: "/3/xizmat8",
        title: t("header.xizmat.l9"),
      },
    ],
    l4: [
      {
        id: 1,
        to: "/4/hujjat",
        title: t("header.hujjat.l1"),
      },
      {
        id: 2,
        to: "/4/hujjat1",
        title: t("header.hujjat.l2"),
      },
      {
        id: 3,
        to: "/4/hujjat2",
        title: t("header.hujjat.l3"),
      },
      {
        id: 4,
        to: "/4/hujjat3",
        title: t("header.hujjat.l4"),
      },
      {
        id: 5,
        to: "/4/hujjat4",
        title: t("header.hujjat.l5"),
      },
      {
        id: 6,
        to: "/4/hujjat5",
        title: t("header.hujjat.l6"),
      },
      {
        id: 7,
        to: "/4/hujjat6",
        title: t("header.hujjat.l7"),
      },
      {
        id: 8,
        to: "/4/hujjat7",
        title: t("header.hujjat.l8"),
      },
    ],
    l5: [
      {
        id: 1,
        to: "/5/open_data_list",
        title: t("header.ochiq.l1"),
      },
      {
        id: 2,
        to: "/5/ochiq1",
        title: t("header.ochiq.l2"),
      },
      {
        id: 3,
        to: "/5/ochiq2",
        title: t("header.ochiq.l3"),
      },
      {
        id: 4,
        to: "/5/ochiq3",
        title: t("header.ochiq.l4"),
      },
    ],
    l6: [
      {
        id: 1,
        to: "/6/news",
        title: t("header.centr.l1"),
      },
      {
        id: 2,
        to: "/6/realese",
        title: t("header.centr.l2"),
      },
      {
        id: 3,
        to: "/6/conference",
        title: t("header.centr.l3"),
      },
      {
        id: 4,
        to: "/6/meetings",
        title: t("header.centr.l4"),
      },
      {
        id: 5,
        to: "/6/speaches",
        title: t("header.centr.l5"),
      },
      {
        id: 6,
        to: "/6/events",
        title: t("header.centr.l6"),
      },
      {
        id: 7,
        to: "/6/centr",
        title: t("header.centr.l7"),
      },
    ],
    l7: [
      {
        id: 1,
        to: "/7/contacts",
        title: t("header.boglanish.l1"),
      },
      {
        id: 2,
        to: "/7/murojat",
        title: t("header.boglanish.l2"),
      },
    ],
  };
  return type ? extraLinks[`l${type}`] : links;
};
