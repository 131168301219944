import { useState } from "react";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import styles from "./OtherNewsCard.module.css";
import { kalendar } from "../../assets";

export const OtherNewsCard = ({ img, title, date }) => {
  let dateObject = new Date();
  let options = { day: "numeric", month: "long" };
  let [number, month] = dateObject
    .toLocaleDateString("ru-RU", options)
    .split(" ");

  const [isVisible, setIsVisible] = useState(false);
  return (
    <Link to="/6/news/1">
      <div className={`${styles.otherNews} ${isVisible ? styles.visible : ""}`}>
        <Waypoint onEnter={() => setIsVisible(true)} />
        <div className={styles.ten}>
          <img src={img} alt="" />
        </div>
        <div>
          <p>{title}</p>
        </div>
        <div className={styles.date}>
          <img src={kalendar} alt="" />
          <span>{number + " " + month}</span>
        </div>
      </div>
    </Link>
  );
};
